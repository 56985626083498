import { useNavigate } from "react-router"
import { message } from 'antd'
import moment from 'moment'
import { UNIDATETIMEFORMAT } from "./systemparameter"


const AUTHTOKEN_KEY = "authToken"
const USERGROUPS_KEY = "userGroups"
const USERID_KEY = "userID"
const USERNAME_KEY = "userName"
const PROJECTID_KEY = "projectID"
const PROJECTNAME_KEY = "projectName"
const LASTACTIVE_KEY = "lastActive"

const BACKGROUNDCOLOR_KEY = "backgroundColor"
const BORDERCOLOR_KEY = "borderColor"

const DISTRICTID_KEY = "districtId"
const DISTRICT_KEY = "district"
const ZONEID_KEY = "zoneId"
const ZONE_KEY = "zone"
const ROADIDID_KEY = "roadIdId"
const ROADID_KEY = "roadId"
const RECORDTYPEID_KEY = "recordTypeId"
const RECORDTYPE_KEY = "recordType"

// For searching
export const SEARCH_DISTRICTID_KEY = "search_districtId"
export const SEARCH_DISTRICT_KEY = "search_district"
export const SEARCH_ZONEID_KEY = "search_zoneId"
export const SEARCH_ZONE_KEY = "search_zone"
export const SEARCH_ROADIDID_KEY = "search_roadIdId"
export const SEARCH_ROADID_KEY = "search_roadId"
export const SEARCH_DATEFROM_KEY = "search_datefrom"
export const SEARCH_DATETO_KEY = "search_dateto"
export const SEARCH_SRT_KEY = "search_srt"
export const SEARCH_RECORDTYPEID_KEY = "search_recordTypeId"
export const SEARCH_RECORDTYPE_KEY = "search_recordType"


export const createUserSession = (userId, username, userGroups, defaultProjectId, defaultProjectName, authToken) => {
    // Each user session must contain at least the following items.
    window.sessionStorage.setItem(AUTHTOKEN_KEY, authToken)
    window.sessionStorage.setItem(USERID_KEY, userId)
    window.sessionStorage.setItem(USERNAME_KEY, username)
    window.sessionStorage.setItem(USERGROUPS_KEY, userGroups)
    window.sessionStorage.setItem(PROJECTID_KEY, defaultProjectId)
    window.sessionStorage.setItem(PROJECTNAME_KEY, defaultProjectName)
    window.sessionStorage.setItem(LASTACTIVE_KEY, new Date())
}

// React Hook (useHistory) can only be called from React function component so function name must start with Capital letter.
export const ValidateUserSession = () => {
    const navigate = useNavigate()

    // Redirect user to login page if not authenticated or session expired.
    const lastActive = moment(window.sessionStorage.getItem(LASTACTIVE_KEY))
    
    if(window.sessionStorage.getItem(AUTHTOKEN_KEY) === null || lastActive.add(parseInt(OTHERSYSPARAM("USER_SESSION_HOUR")), 'hours').isBefore(moment())) {
        navigate({ pathname: "/" })
        //message.info("Please kindly login.")
        return false
    }

    return true
}

export const getUserAuthToken = () => {
    return window.sessionStorage.getItem(AUTHTOKEN_KEY)
}

export const refreshUserSession = () => {
    window.sessionStorage.setItem(LASTACTIVE_KEY, moment().format(UNIDATETIMEFORMAT))
}

export const clearUserSession = () => {
    window.sessionStorage.clear()
}

export const getUserId = () => {
    return window.sessionStorage.getItem(USERID_KEY)
}

export const getUsername = () => {
    return window.sessionStorage.getItem(USERNAME_KEY)
}

export const getUserGroup = () => {
    try{
        return window.sessionStorage.getItem(USERGROUPS_KEY).split(",")
    }
    catch(error) {
        window.location.href = "/"
    }
}

export const setUserProject = (projectId, projectName) => {
    window.sessionStorage.setItem(PROJECTID_KEY, projectId)    
    window.sessionStorage.setItem(PROJECTNAME_KEY, projectName)
}

export const getUserProjectId = () => {
    return window.sessionStorage.getItem(PROJECTID_KEY)    
}

export const getUserProjectName = () => {
    return window.sessionStorage.getItem(PROJECTNAME_KEY)    
}

// System parameters from database
export const storeSystemParameter = (key, value) => {
    if(window.sessionStorage.getItem(key) !== null) {
        message.error(`Duplicated system parameter detected: ${key}: ${value}.`)
        return
    }

    if(!Array.isArray(value))
        window.sessionStorage.setItem(key, value)
    else
        window.sessionStorage.setItem(key, JSON.stringify(value))
}

// Always return an interger (primary key)
export const SYSPARAM = (key) => {
    try{
        if(window.sessionStorage.getItem(key) === null) {
            const sessionValid = ValidateUserSession()
            if(sessionValid) message.error(`Unknown system parameter requested: ${key}`)
        }

        try {
            return JSON.parse(window.sessionStorage.getItem(key))
        }
        catch (e) {
            return parseInt(window.sessionStorage.getItem(key))
        }
    }
    catch(error) {
        window.location.href = "/"
    }
}

// Always return string
export const OTHERSYSPARAM = (key) => {
    try{
        if(window.sessionStorage.getItem(key) === null) {
            const sessionValid = ValidateUserSession()
            if(sessionValid) message.error(`Unknown other system parameter requested: ${key}`)
        }

        try {
            return JSON.parse(window.sessionStorage.getItem(key))
        }
        catch (e) {
            return window.sessionStorage.getItem(key)
        }
    }
    catch(error) {
        window.location.href = "/"
    } 
}

export const storeBackgroundColor = (colorCode) => {
    window.sessionStorage.setItem(BACKGROUNDCOLOR_KEY, colorCode)
}

export const storeBorderColor = (color) => {
    window.sessionStorage.setItem(BORDERCOLOR_KEY, color)
}

export const retrieveBackgroundColor = () => {
    return window.sessionStorage.getItem(BACKGROUNDCOLOR_KEY)
}

export const retrieveBorderColor = () => {
    return window.sessionStorage.getItem(BORDERCOLOR_KEY)
}

// District
export const storeDistrictId = (districtId) => {
    window.sessionStorage.setItem(DISTRICTID_KEY, districtId)
}
export const getDistrictId = () => {
    return parseInt(window.sessionStorage.getItem(DISTRICTID_KEY))
}
export const storeDistrict = (district) => {
    window.sessionStorage.setItem(DISTRICT_KEY, district)
}
export const getDistrict = () => {
    return window.sessionStorage.getItem(DISTRICT_KEY)
}

// Zone
export const storeZoneId = (zoneId) => {
    window.sessionStorage.setItem(ZONEID_KEY, zoneId)
}
export const getZoneId = () => {
    return parseInt(window.sessionStorage.getItem(ZONEID_KEY))
}
export const storeZone = (zone) => {
    window.sessionStorage.setItem(ZONE_KEY, zone)
}
export const getZone = () => {
    return window.sessionStorage.getItem(ZONE_KEY)
}

// Road ID
export const storeRoadIdId = (roadIdId) => {
    window.sessionStorage.setItem(ROADIDID_KEY, roadIdId)
}
export const getRoadIdId = () => {
    return parseInt(window.sessionStorage.getItem(ROADIDID_KEY))
}
export const storeRoadId = (roadId) => {
    window.sessionStorage.setItem(ROADID_KEY, roadId)
}
export const getRoadId = () => {
    return window.sessionStorage.getItem(ROADID_KEY)
}

// Record Type
export const storeRecordTypeId = (recordTypeId) => {
    window.sessionStorage.setItem(RECORDTYPEID_KEY, recordTypeId)
}
export const getRecordTypeId = () => {
    return parseInt(window.sessionStorage.getItem(RECORDTYPEID_KEY))
}
export const storeRecordType = (recordType) => {
    window.sessionStorage.setItem(RECORDTYPE_KEY, recordType)
}
export const getRecordType = () => {
    return window.sessionStorage.getItem(RECORDTYPE_KEY)
}