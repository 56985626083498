import { Row, Col } from 'antd'

const MainFooter = ({breadCrumb}) => {
    return(
        <Row justify="center">
            <Col>{breadCrumb}</Col>
        </Row>
    )
}

export default MainFooter