import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from './systemparameter'
import { reportError } from './utility'
import { getUserAuthToken, getUserProjectId, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const RoadSelect = ({withBlank, defaultValue, onChange, allowClear, noStyle = false, marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [roadOption, setRoadOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(!noStyle ? isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH : SEARCHOPTIONVIEWWIDTH)
        
    const getAllRoad = () => {
        axios.get(`${APIURL}road/`, {
            params: { 
                project: getUserProjectId()
            }, 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( road => <Option key={road.id}>{`${road.road_name} (${road.list} ${road.road_id})`}</Option>))
            setRoadOption(options)
        })
        .catch( error => {
            reportError(error, `<RoadSelect /> Failed to get road.`)
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onSelectionChange = (e, value) => {
         onChange(e, value)
    }

    useEffect(() => {
        getAllRoad()
    }, []) 
    
    return(
        <Select style={{width: optionWidth, marginLeft: marginLeft}} onChange={onSelectionChange} allowClear={allowClear} showSearch optionFilterProp="children" 
            defaultValue={defaultValue}
            filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {/* filterOption={(input, option) => (option?.value ?? '').includes(input)} */}
            {/* filterSort={(optionA, optionB) => (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}> */}
            {roadOption}
        </Select>
    )
}

export default RoadSelect