import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from './systemparameter'
import { reportError } from './utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const UserGroupSelect = ({withBlank, defaultValue, onChange, allowClear, marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [userGroupOption, setUserGroupOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
        
    const getAllUserGroup = () => {
        axios.get(`${APIURL}usergroup/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( usergroup => <Option key={usergroup.id}>{usergroup.name}</Option>))
            setUserGroupOption(options)
        })
        .catch( error => {
            reportError(error, "<UserGroupSelect /> Failed to get user group(s).")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onSelectionChange = (e, value) => {
         onChange(e, value)
    }

    useEffect(() => {
        getAllUserGroup()
    }, []) 
    
    return(
        <Select style={{width: optionWidth, marginLeft: marginLeft}} onChange={onSelectionChange} allowClear={allowClear} showSearch optionFilterProp="children" defaultValue={defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {userGroupOption}
        </Select>
    )
}

export default UserGroupSelect