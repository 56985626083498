import { useState, useEffect } from 'react';
import './App.css';
import Router from './common/router';
import { ConfigProvider, theme } from 'antd';
import { CookiesProvider, useCookies } from "react-cookie"


function App() {
    const [cookies, setCookies] = useCookies(['useDarkTheme'])
    const [themeAlgorithm, setThemeAlgorithm] = useState(cookies['useDarkTheme'] == '1' ? [theme.compactAlgorithm, theme.darkAlgorithm] : [theme.compactAlgorithm])

    useEffect(() => {
        document.title = "IAS"
    }, []);

    return (
        <ConfigProvider 
            theme={{ 
                algorithm: themeAlgorithm,
                token: { fontFamily: 'Segoe UI'  } 
            }} >
            <CookiesProvider>
                <Router />
            </CookiesProvider>
        </ConfigProvider>
    );
}

export default App;