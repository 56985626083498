import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./login"
import ProjectMain from "../setup/projectmain"
import ProjectUpdate from "../setup/projectupdate"
import ProjectNew from "../setup/projectnew"
import ChangePassword from "../setup/changepassword"
import UserMain from "../setup/usermain"
import UserNew from "../setup/usernew"
import UserUpdate from "../setup/userupdate"
import RoadAuditMain from "../roadaudit/roadauditmain"
import RoadAuditNew from "../roadaudit/roadauditnew"
import RoadAuditUpdate from "../roadaudit/roadauditupdate"
import RoadMain from "../road/roadmain"


const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route index path="/" element={<Login />} />

            <Route path="/roadaudit" element={<RoadAuditMain />} />
            <Route path="/roadauditnew" element={<RoadAuditNew />} />
            <Route path="/roadauditupdate" element={<RoadAuditUpdate />} />
            
            <Route path="/project" element={<ProjectMain />} />
            <Route path="/projectnew" element={<ProjectNew />} />
            <Route path="/projectupdate" element={<ProjectUpdate />} />

            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/user" element={<UserMain />} />
            <Route path="/usernew" element={<UserNew />} />
            <Route path="/userupdate" element={<UserUpdate />} />

            <Route path="/roadmap" element={<RoadMain />} />
            
        </Routes>
    </BrowserRouter>
)

export default Router