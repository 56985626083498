import { Table, Row, Col, Space, Button, Collapse, Pagination, Spin, Image, Badge, Form, Upload, message } from 'antd'
import  { useEffect, useState, useRef } from 'react'
import axios from "axios"
import { UploadOutlined } from "@ant-design/icons"
import { APIURL, LOADING, PAGESIZE, PAGINATIONSIZE, FALLBACK, IMGURL, ROADMAPIMGFOLDER } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserProjectId, SEARCH_DISTRICTID_KEY, SEARCH_DISTRICT_KEY, SEARCH_ZONEID_KEY, SEARCH_ZONE_KEY, SEARCH_ROADIDID_KEY, SEARCH_ROADID_KEY } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs'
import CommonSearchFormItem from '../common/commonsearchformitem'

const { Panel } = Collapse


const RoadTable = () => {
    const [commonSearchForm] = Form.useForm()

    //const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    //const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
    
    //-------------------------------------
    // Always get previously stored values.
    const [districtId, setDistrictId] = useState(window.sessionStorage.getItem(SEARCH_DISTRICTID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_DISTRICTID_KEY)) : 0)
    const [district, setDistrict] = useState(window.sessionStorage.getItem(SEARCH_DISTRICT_KEY))
    const [zoneId, setZoneId] = useState(window.sessionStorage.getItem(SEARCH_ZONEID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_ZONEID_KEY)) : 0)
    const [zone, setZone] = useState(window.sessionStorage.getItem(SEARCH_ZONE_KEY))
    const [roadId, setRoadId] = useState(window.sessionStorage.getItem(SEARCH_ROADIDID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_ROADIDID_KEY)) : 0)
    const [road, setRoad] = useState(window.sessionStorage.getItem(SEARCH_ROADID_KEY))

    //-------------------------------------
    const [roadDataSource, setRoadDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [previewPhotos, setPreviewPhotos] = useState([])
        
    const [uploading, setUploading] = useState(false)
    let roadIdIdRef = useRef(0)    
    let roadIdRef = useRef('')
    const [roadImageFile, setRoadImageFile] = useState([])
    

    const searchRoad = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}roadpaginated/`, {
            params: { 
                project: getUserProjectId(),
                district: districtId,
                zone: zoneId,
                road: roadId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search road.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( road => {
            data.push({
                id: road.id,
                district: road.district_data.name,
                zone: road.zone_data.name,
                list: road.list,
                roadIdId: road.id,
                roadIdOriginal: road.road_id,
                roadId: `${road.list} ${road.road_id}`,
                roadName: road.road_name,
                roadNameAndId: `${road.road_name} (${road.list} ${road.road_id})`,
                img: `${road?.img}?now=${dayjs().valueOf()}`,
                srt: road.srt,
                roadLength: road.road_length,
                affectedLength: road.affected_length_m
            })
        })
        
        setRoadDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const showPhotos = (e, record) => {
        e.stopPropagation()

        const photos = []
        photos.push(<Image fallback={FALLBACK} src={`${record.img}?now=${new Date()}`}/>)
        setPreviewPhotos(photos)
        setVisible(true)
    }
    
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchRoad(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchRoad(1)
    }

    const onReset = () => {
        window.sessionStorage.setItem(SEARCH_DISTRICTID_KEY, 0)
        window.sessionStorage.setItem(SEARCH_DISTRICT_KEY, '')
        window.sessionStorage.setItem(SEARCH_ZONEID_KEY, 0)
        window.sessionStorage.setItem(SEARCH_ZONE_KEY, '')
        window.sessionStorage.setItem(SEARCH_ROADIDID_KEY, 0)
        window.sessionStorage.setItem(SEARCH_ROADID_KEY, '')
        
        window.location.reload()
    }

    const onRowClick = (record, rowIndex) => {
    }

    const setRoadIdToUploadMap = (e, record) => {
        roadIdIdRef.current = record.id        
        roadIdRef.current = record.roadIdOriginal
    }

    const onSearchDistrictChange = (e, value) => {
        if(e != undefined) {
            setDistrictId(e)
            setDistrict(value.children)
        }
        else {
            setDistrictId(0)
            setDistrict("")
        }
    }

    const onSearchZoneChange = (e, value) => {
        if(e != undefined) {
            setZoneId(e)
            setZone(value.children)
        }
        else {
            setZoneId(0)
            setZone("")
        }
    }

    const onSearchRoadChange = (e, value) => {
        if(e != undefined) {
            setRoadId(e)
            setRoad(value.children)
        }
        else {
            setRoadId(0)
            setRoad("")
        }
    }

    useEffect(() => {
        searchRoad(currentPage)
    }, [])

    const props = {
        name: "img",
        action: `${APIURL}road/uploadmap/${roadIdIdRef.current}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 2,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        showUploadList: false,
        fileList: roadImageFile,
        /*onRemove: file => {
            setEggImageFile([])
            removeEggDevelopmentImage()
            setHideRemoveImageButton(true)
        },*/
        beforeUpload: file => {
            setRoadImageFile([file])
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)
                
                const fileArray = info.file.name.split('.')
                const fileExtention = fileArray[fileArray.length - 1]
                
                const newRoadDataSource = []
                roadDataSource.forEach( road => {
                    if(road.id != roadIdIdRef.current)
                        newRoadDataSource.push(road)
                    else {
                        const roadWithNewMap = road
                        roadWithNewMap.img = `${IMGURL}${ROADMAPIMGFOLDER}/${roadIdRef.current}.${fileExtention}?now=${dayjs().valueOf()}`
                        newRoadDataSource.push(roadWithNewMap)
                    }
                })
                setRoadDataSource(newRoadDataSource)

                message.info("Image uploaded.")
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                setIsLoading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
        maxCount: 1,
        //roadImageFile,
    }

    const columns = [
        { title: 'District', dataIndex: 'district', key: 'district', align: 'center', sorter: (a, b) => a.distict.localeCompare(b.distict) },
        { title: 'Zone', dataIndex: 'zone', key: 'zone', align: 'center', sorter: (a, b) => a.zone.localeCompare(b.zone) },
        { title: 'List', dataIndex: 'list', key: 'list', align: 'center', sorter: (a, b) => a.list.localeCompare(b.list) },
        { title: 'Road ID', dataIndex: 'roadId', key: 'roadId', align: 'center', sorter: (a, b) => a.roadId.localeCompare(b.roadId) },
        { title: 'Road Name', dataIndex: 'roadName', key: 'roadName', align: 'center', sorter: (a, b) => a.roadName.localeCompare(b.roadName) },
        { title: 'SRT', dataIndex: 'srt', key: 'srt', align: 'center', sorter: (a, b) => a.srt.localeCompare(b.srt) },
        { title: 'Road Length (m)', dataIndex: 'roadLength', key: 'roadLength', align: 'center', sorter: (a, b) => a.roadLength.localeCompare(b.roadLength) },
        { title: 'Affected Length (m)', dataIndex: 'affectedLength', key: 'affectedLength', align: 'center', sorter: (a, b) => a.affectedLength.localeCompare(b.affectedLength) },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Badge count={record.photoCount}>
                            <Image width={50} fallback={FALLBACK} src={img} preview={false} onClick={(e) => showPhotos(e, record) }/>
                        </Badge>
                    </>
                else
                    return null
            }
        },
        { key: 'action', align: 'center', width: 100,
            render: (record) => {
                return <Upload {...props}>
                    <Button icon={<UploadOutlined />} loading={uploading} onClick={e => setRoadIdToUploadMap(e, record)}>Upload</Button>
                </Upload>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search road" key="1">
                        <CommonSearchFormItem onDistrictChange={onSearchDistrictChange} onZoneChange={onSearchZoneChange} onRoadChange={onSearchRoadChange} 
                            commonSearchForm={commonSearchForm} defaultDistrictId={districtId} defaultDistrict={district} defaultZoenId={zoneId} 
                            defaultZone={zone} defaultRoadIdId={roadId} defaultRoadId={road}/>

                        <Row><Col><Space><div /></Space></Col></Row>
                        
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={roadDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>

        <div style={{ display: 'none'}}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>{previewPhotos}</Image.PreviewGroup>
        </div>

        </Spin>
        </>
    )
}

export default RoadTable