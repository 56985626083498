import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH } from "./systemparameter"
import { reportError } from "./utility"
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const SRTSelect = ({withBlank, defaultValue, onChange, allowClear = false, style = '', marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [srtOption, setSrtOption] = useState([])
    //const [optionWidth, setOptionWidth] = useState(!noStyle ? isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH : SEARCHOPTIONVIEWWIDTH)
            
    //---------
    // Load all
    //---------
    const getAllSrt = () => {
        let options = []
        if(withBlank) options.push(<Option key={''}>{" "}</Option>) // Blank
        options = options.concat(<Option key={'1'}>1</Option>)
        options = options.concat(<Option key={'2'}>2</Option>)
        options = options.concat(<Option key={'1 & 2'}>1 & 2</Option>)
        setSrtOption(options)
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllSrt()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} style={style} onChange={(e, value) => onSelectionChange(e, value)} allowClear={allowClear} showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {srtOption}
        </Select>
    )
}

export default SRTSelect