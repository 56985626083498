import { useCookies } from "react-cookie"
import { useState } from 'react'
import { Menu, message } from 'antd'
import { SettingOutlined, UserOutlined, CheckSquareOutlined, FormatPainterOutlined } from '@ant-design/icons'
import { useNavigate } from "react-router"
import { DARKTHEMEBACKGROUNDCOLOR, DARKTHEMEBORDERCOLOR, LIGHTTHEMEBACKGROUNDCOLOR, LIGHTTHEMEBORDERCOLOR, MENU_CHANGEPASSWORD, MENU_PROJECT, MENU_ROADAUDIT, MENU_ROADMAP, MENU_USER, MENUKEY_CHANGEPASSWORD, MENUKEY_PROJECT, MENUKEY_ROADAUDIT, MENUKEY_ROADMAP, MENUKEY_THEMESWITCH, MENUKEY_USER, MENUPATH_CHANGEPASSWORD, MENUPATH_PROJECT, MENUPATH_ROADAUDIT, MENUPATH_ROADMAP, MENUPATH_USER, 
     } from './systemparameter'

import { getUserGroup, OTHERSYSPARAM, storeBackgroundColor, storeBorderColor } from './usersession'

const { SubMenu } = Menu

const ACISMenu = ({setDrawerVisible}) => {
    const navigate = useNavigate()

    const [cookies, setCookies] = useCookies(['useDarkTheme'])
    const [themeLabel, setThemeLabel] = useState(cookies['useDarkTheme'] == '1' ? 'Light Theme' : 'Dark Theme')
    
    //-------------
    // Handle click
    //-------------
    const onClick = (e) => {
        // Close drawer
        setDrawerVisible(false) 
        
        switch(parseInt(e.key)) {
            case MENUKEY_ROADAUDIT:
                navigate(MENUPATH_ROADAUDIT)
                break

            case MENUKEY_CHANGEPASSWORD:
                navigate(MENUPATH_CHANGEPASSWORD)
                break
            case MENUKEY_USER:
                navigate(MENUPATH_USER)
                break
            case MENUKEY_PROJECT:
                navigate(MENUPATH_PROJECT)
                break
            case MENUKEY_ROADMAP:
                navigate(MENUPATH_ROADMAP)
                break
            
            case MENUKEY_THEMESWITCH:
                if(cookies['useDarkTheme'] == '1') {
                    setCookies('useDarkTheme', '0', { path: '/', SameSite: 'Strict' })
                    storeBackgroundColor(LIGHTTHEMEBACKGROUNDCOLOR)
                    storeBorderColor(LIGHTTHEMEBORDERCOLOR)
                }
                else {
                    setCookies('useDarkTheme', '1', { path: '/', SameSite: 'Strict' })
                    storeBackgroundColor(DARKTHEMEBACKGROUNDCOLOR)
                    storeBorderColor(DARKTHEMEBORDERCOLOR)
                }

                window.location.reload()
                break
            default: 
                message.error("Page not found.")
        }
    }

    return (
        <Menu onClick={onClick} mode="inline" /*defaultSelectedKeys={['1']}*/ defaultOpenKeys={[]}>
            <SubMenu key="userMenu" icon={<UserOutlined />} title="Account">
                <Menu.Item key={MENUKEY_CHANGEPASSWORD}>{MENU_CHANGEPASSWORD}</Menu.Item>
                { getUserGroup().includes(OTHERSYSPARAM("ADMIN")) &&
                    <>
                        <Menu.Item key={MENUKEY_USER}>{MENU_USER}</Menu.Item>
                    </>
                }
            </SubMenu>

            <Menu.Item key={MENUKEY_ROADAUDIT} icon={<CheckSquareOutlined />}>{MENU_ROADAUDIT}</Menu.Item>
            
            { getUserGroup().includes(OTHERSYSPARAM("ADMIN")) &&
                <>
                <SubMenu key="setupMenu" icon={<SettingOutlined />} title="Setup">
                    <Menu.Item key={MENUKEY_PROJECT}>{MENU_PROJECT}</Menu.Item>
                    <Menu.Item key={MENUKEY_ROADMAP}>{MENU_ROADMAP}</Menu.Item>
                    <Menu.Item key={MENUKEY_THEMESWITCH} icon={<FormatPainterOutlined />}>{themeLabel}</Menu.Item>
                </SubMenu>
                </>
            }
        </Menu>
  )
}

export default ACISMenu