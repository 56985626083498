import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Descriptions, Spin, Upload, DatePicker, Input, Table, Image } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, DATETIMEFORMAT, LOADING, SEARCHOPTIONVIEWWIDTH, MAXPHOTOS, MENUPATH_ROADAUDIT, FALLBACK } from '../common/systemparameter'
import { getBase64, reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserProjectId, retrieveBackgroundColor, getUserId, getRoadId, storeRoadId, getRoadIdId, storeRoadIdId, getDistrictId, getDistrict, getZoneId, getZone, storeDistrictId, storeDistrict, storeZoneId, storeZone, getRecordTypeId, getRecordType, SYSPARAM, storeRecordType, storeRecordTypeId } from "../common/usersession"
import dayjs from 'dayjs'
import { walltiles } from '../common/layout'
import { EditableCell, EditableRow } from '../common/editabletablecell'
import CommonSearchFormItem from '../common/commonsearchformitem'
import RecordTypeSelect from '../common/recordtypesselect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { TextArea } = Input


const RoadAuditNew = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    
    const [districtId, setDistrictId] = useState(!isNaN(getDistrictId()) ? getDistrictId() : 0)
    const [district, setDistrict] = useState(getDistrict() == null ? "" : getDistrict())
    const [zoneId, setZoneId] = useState(!isNaN(getZoneId()) ? getZoneId() : 0)
    const [zone, setZone] = useState(getZone() == null ? "" : getZone())
    const [roadIdId, setRoadIdId] = useState(!isNaN(getRoadIdId()) ? getRoadIdId() : 0)
    const [roadId, setRoadId] = useState(getRoadId() == null ? "" : getRoadId())
    const [recordTypeId, setRecordTypeId] = useState(!isNaN(getRecordTypeId()) ? getRecordTypeId() : 0)
    const [recordType, setRecordType] = useState(getRecordType() == null ? "" : getRecordType())
    const [auditedOn, setAuditedOn] = useState(dayjs())
    //const [photoFile, setPhotoFile] = useState([])
    const photoFile = useRef([])
    const [photoDataSource, setPhotoDataSource] = useState([])
    const [findings, setFindings] = useState('')
    const [conclusion, setConclusion] = useState('')
    
    const createRoadAudit = () => {
        storeDistrictId(districtId)
        storeDistrict(district)
        storeZoneId(zoneId)
        storeZone(zone)
        storeRoadIdId(roadIdId)
        storeRoadId(roadId)
        storeRecordTypeId(recordTypeId)
        storeRecordType(recordType)

        form.validateFields()
        .then( values => {
            axios.post(`${APIURL}roadaudit/create/`, {
                project: getUserProjectId(),
                auditor_user_id: getUserId(),
                road: roadIdId,
                audited_on: auditedOn,
                gps_location: values.gpsLocation != "" ? values.gpsLocation : '',
                chainage_from: values.chainageFrom != "" ? values.chainageFrom : '',
                chainage_to: values.chainageTo != "" ? values.chainageTo : '',
                record_type: recordTypeId,
                findings: recordTypeId != SYSPARAM("Conclusion") ? values.findings : '',
                conclusion: recordTypeId == SYSPARAM("Conclusion") ? values.conclusion : '',
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: { "Authorization": `Token ${getUserAuthToken()}`, }
            })
            .then( response => {
                setIsLoading(true)
                let count = 0

                for(const file of photoFile.current) {
                    let data = new FormData()
                    data.append("img", file)

                    //const img = photoDataSource.filter( photo => photo.filename == file.name)
                    const img = photoDataSource.filter( photo => photo.key == file.uid)
                    data.append("remark", img[0].remark)
                    
                    // Sanitize chainage value.
                    let chainageFrom = img[0].chainageFrom
                    if(String(chainageFrom).trim() == '') chainageFrom = ''
                    let chainageTo = img[0].chainageTo
                    if(String(chainageTo).trim() == '') chainageTo = ''
                    
                    data.append("chainage_from", chainageFrom)
                    data.append("chainage_to", chainageTo)
                    data.append("road_audit", response.data.id)
        
                    fetch(`${APIURL}roadaudit/uploadphoto/${response.data.id}/`, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Token ${getUserAuthToken()}`,
                        },
                        body: data,
                    })
                    .then( response => {
                        count++

                        if(count == photoFile.current.length) {
                            setIsLoading(false)
                            message.info("Road audit record saved.")
                            navigate(MENUPATH_ROADAUDIT)
                        }
                    })
                    .catch( error => {
                        setIsLoading(false)
                        reportError(error, `Failed to upload photo.`)
                    })
                    .finally(() => {
                        refreshUserSession()
                    })
                }
            })
            .catch( error => {
                reportError(error, `Failed to save road audit record.`)
            })
            .finally(() => {
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const removePhoto = (e, record) => {
        //setPhotoFile(photoFile.filter(photo => photo.uid != record.key))
        photoFile.current = photoFile.current.filter(photo => photo.uid != record.key)
        
        const numberedPhotos = [] 
        let idx = 1
        const filteredPhotoDataSource = photoDataSource.filter(photo => photo.key != record.key)
        for(const photo of filteredPhotoDataSource) {
            photo["idx"] = `${idx}.`
            numberedPhotos.push(photo)
            idx++
        }
        setPhotoDataSource(numberedPhotos)
    }

    const onSave = async (e) => {
        if(roadIdId == 0) {
            message.warning('Road ID is required.')        
            return
        }

        if(photoDataSource.length == 0) {
            message.warning('Photo is required.')
            return
        }

        if(photoDataSource.length > MAXPHOTOS) {
            message.warning(`Maximum of only ${MAXPHOTOS} photos are allowed.`)
            return
        }

        for(const photo of photoDataSource) {
            if(String(photo.chainageFrom).trim() == '' && String(photo.chainageTo).trim() != '') {
                message.warning('Missing Chainage (From) detected.')
                return
            }

            if(photo.chainageFrom != undefined && photo.chainageFrom != "" && isNaN(parseInt(photo.chainageFrom))) {
                message.warning('Invalid Chainage (From) detected.')
                return
            }

            if(photo.chainageTo != undefined && photo.chainageTo != "" && isNaN(parseInt(photo.chainageTo))) {
                message.warning('Invalid Chainage (To) detected.')
                return
            }
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create road audit record confirmed?</p></Space>,
            onOk() { createRoadAudit() },
            onCancel() {},
            centered: true
        })
    }

    const onAuditedOnChange = (datetime) => {
        setAuditedOn(datetime)
    }

    const onDistrictChange = (e, value) => {
        if(e != undefined) {
            setDistrictId(e)
            setDistrict(value.children)
        }
        else {
            setDistrictId(0)
            setDistrict('')
        }
    }

    const onZoneChange = (e, value) => {
        if(e != undefined) {
            setZoneId(e)
            setZone(value.children)
        }
        else {
            setZoneId(0)
            setZone('')
        }
    }

    const onRoadIdChange = (e, value) => {
        if(e != undefined) {
            setRoadIdId(e)
            setRoadId(value.children)
        }
        else {
            setRoadIdId(0)
            setRoadId('')
        }
    }

    const onRecordTypeChange = (e, value) => {
        if(e != undefined) {
            setRecordTypeId(e)
            setRecordType(value.children)
        }
        else {
            setRecordTypeId(0)
            setRecordType('')
        }
    }

    const validateAuditedOn = (() => {
        if(auditedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Audited on is required."))
    })

    const validateRecordType = (() => {
        if(recordTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Record type is required."))
    })

    const onBack = () => {
        navigate(MENUPATH_ROADAUDIT)
    }

    const props = {
        showUploadList: false,
        /* onRemove: file => {
             setPhotoFile(photoFile.filter(photo => photo.name != file.name))
        },*/
        beforeUpload: async (file, fileList) => {
            if(fileList.length > 10) {
                message.warning(`Please do not select more than 10 photos.`)
                return false
            }

            if(photoDataSource.length + fileList.length > MAXPHOTOS) {
                message.warning(`Number of photos exceeding ${MAXPHOTOS}.`)
                return false
            }

            const populatePhotoDataSource = () => {
                //photoFile.current = photoFile.current.concat(fileList)
                fileList.forEach( file => {
                    let photoUIDs = photoFile.current.map( file => file.uid )
                    if(!photoUIDs.includes(file.uid))
                        photoFile.current = photoFile.current.concat(file)
                })

                let count = 0
                let imgDataSource = []

                for(const file of fileList) {
                    const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
                    if(!isImage) {
                        message.error(`${file.name} is not an image file.`)
                        return false
                    }

                    let idx = photoDataSource.length
                    
                    getBase64(file)
                    .then( b64 => {
                        count++

                        //console.log(`${file.uid}_${dayjs().valueOf()}`)
                        imgDataSource.push({
                            key: file.uid,
                            filename: file.name,
                            img: b64,
                            chainageFrom: '',
                            chainageTo: '',
                            remark: '',
                            idx: `${idx + count}.`
                        })
                        
                        if(count == fileList.length) setPhotoDataSource(photoDataSource.concat(imgDataSource))
                    })
                }
            }

            populatePhotoDataSource()
            
            return false
        },
        multiple: true,
        maxCount: {MAXPHOTOS},
        fileList: photoFile.current,
    }

    useEffect(() => {
    }, [])

    const photoColumns = [
        { title: 'No.', dataIndex: 'idx', key: 'idx', align: 'center' },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Image width={200} fallback={FALLBACK} src={img} onClick={(e) => e.stopPropagation() }/>
                    </>
                else
                    return null
            }
        },
        {title: 'Chainage (From)', dataIndex: 'chainageFrom', key: 'chainageFrom', width: '10vw', align: 'center', editable: true },
        {title: 'Chainage (To)', dataIndex: 'chainageTo', key: 'chainageTo', width: '10vw', align: 'center', editable: true },
        {title: 'Remark', dataIndex: 'remark', key: 'remark', width: '40vw', align: 'left', editable: true },
        { key: 'action', align: 'center',
            render: (record) => {
                return <Popconfirm title="Delete photo confirmed?" 
                    onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => removePhoto(e, record)} 
                    okText="Yes" cancelText="No">
                    <Button danger type="primary" icon={<DeleteOutlined/>} />
                </Popconfirm>
            }
        }
    ]

    const handleSave = (row) => {
        const newData = [...photoDataSource]
        const index = newData.findIndex((item) => row.key === item.key)
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })

        setPhotoDataSource(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }

    const columns = photoColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        }
    })

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Road Audit">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create road audit record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <CommonSearchFormItem onDistrictChange={onDistrictChange} onZoneChange={onZoneChange} onRoadChange={onRoadIdChange} horizontal={false} 
                    withBlank={true} commonSearchForm={form} defaultRoadId={roadId} />

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="gpsLocation" label="GPS Location">
                        <Input placeholder="GPS Location" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="chainageFrom" label="Chainage (From)">
                        <Input placeholder="Chainage (From)" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="chainageTo" label="Chainage (To)">
                        <Input placeholder="Chainage (To)" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="auditedOn" label="Audited On"
                        rules={[
                            { required: true, validator: validateAuditedOn },
                        ]}>
                        <DatePicker showTime onChange={onAuditedOnChange} defaultValue={auditedOn} format={DATETIMEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="photoFile" label={`Photos (Each addition allows 10 photos. Maximum ${MAXPHOTOS} photos are allowed in total.)`}
                        rules={[ 
                            { required: true }
                            ]}>
                        <Upload {...props} accept=".jpg,.png,.gif">
                            <Button icon={<UploadOutlined />}>Add Photo</Button>
                        </Upload>
                    </Form.Item> 

                    <Form.Item name="recordType" label="Record Type"
                        rules={[
                            { required: true, validator: validateRecordType },
                        ]}>
                        <RecordTypeSelect withBlank={false} marginLeft={10} onChange={onRecordTypeChange} defaultValue={recordType}/>
                    </Form.Item>

                    { recordTypeId != SYSPARAM("Conclusion") &&
                        <Form.Item name="findings" label="Onsite Findings">
                            {/* <Input placeholder="Onsite Findings" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} /> */}
                            <TextArea rows={5} placeholder="Onsite Findings" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                        </Form.Item>
                    }

                    { recordTypeId == SYSPARAM("Conclusion") &&
                        <Form.Item name="conclusion" label="Conclusion">
                            {/* <Input placeholder="Conclusion" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} /> */}
                            <TextArea rows={5} placeholder="Conclusion" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                        </Form.Item>
                    }

                    <Row><Col><Space><div /></Space></Col></Row>

                    { photoDataSource.length > 0 &&
                        <>
                        <Row justify="center">
                            <Col span={12}>
                                <Table bordered columns={columns} components={components} dataSource={photoDataSource} pagination={false} />
                            </Col>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" icon={<LeftOutlined />}>Cancel</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Road Audit:"
                    subTitle="Create road audit record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default RoadAuditNew