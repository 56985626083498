import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, Descriptions, Select, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, LockOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, LOADING, MENUPATH_USER, SEARCHOPTIONVIEWWIDTH } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import ProjectSelect from '../common/projectselect'
import UserGroupSelect from '../common/usergroupselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { Option } = Select


const UserNew = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [defaultProjectId, setDefaultProjectId] = useState(0)
    const [userGroupId, setUserGroupId] = useState(0)
    const [allowedProjectIds, setAllowedProjectIds] = useState([])
    const [allowedProjectsOption, setAllowedProjectsOption] = useState([])
    const [isLoading, setIsLoading] = useState(false)
        
    const createUser = () => {
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${APIURL}user/create/`, {
                username: values.username,
                email: values.email,
                password: values.password,
                default_project: defaultProjectId,
                allowed_projects: allowedProjectIds,
                user_group: userGroupId,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`New user ${values.username} created.`)
                navigate(MENUPATH_USER)
            })
            .catch( error => {
                reportError(error, "Failed to create new user.")
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const getAllowableProjectsOption = (defaultProjectId) => {
        setIsLoading(true)

        axios.get(`${APIURL}project/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            const data = response.data.results.filter(project => project.id != defaultProjectId)
            let options = data.map( project => <Option key={project.id}>{project.name}</Option>)
            setAllowedProjectsOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get project(s).")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onSave = async (e) => {
        form.validateFields()
        .then( values => {
            if(values.password.trim().length < 6) {
                message.warn("Invalid password.")
                return
            }

            if(values.confirmPassword.trim().length < 6) {
                message.warn("Invalid confirm password.")
                return
            }

            if(values.password != values.confirmPassword) {
                message.warn("Password do not match.")
                return
            }
            else {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Create user confirmed?</p></Space>,
                    onOk() { createUser() },
                    onCancel() {},
                    centered: true
                })
            }
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const onBack = () => {
        navigate(MENUPATH_USER)
    }

    const onDefaultProjectChange = (e, value) => {
        setAllowedProjectsOption([])
        form.setFieldsValue({
            allowedProjects: []
        })
        
        if(e != undefined) {
            setDefaultProjectId(e)
            getAllowableProjectsOption(e)
        }
        else 
            setDefaultProjectId(0)
    }

    const onAllowedProjectsChange = (e) => {
        if(e != undefined) 
            setAllowedProjectIds(e)
        else 
            setDefaultProjectId([])
    }

    const onUserGroupChange = (e, value) => {
        if(e != undefined) 
            setUserGroupId(e)
        else 
            setUserGroupId(0)
    }

    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="User">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create user</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="username" label="Username"
                        rules={[
                            { required: true, message: "Username is required."},
                        ]}>
                        <Input maxLength={150} placeholder="Username" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="email" label="Email"
                        rules={[
                            { required: true, type: "email", message: "Email is required."},
                        ]}>
                        <Input maxLength={254} placeholder="Email" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="password" label="Password"
                        rules={[
                            { required: true, message: "Password is required."},
                        ]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={6} maxLength={20} placeholder="New Password" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="confirmPassword" label="Confirm Password"
                        rules={[
                            { required: true, message: "Confirm password is required." },
                        ]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={6} maxLength={20} placeholder="Confirm New Password" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="defaultProject" label="Default Project"
                        rules={[
                            { required: true, message: "Default project is required." },
                        ]}>
                        <ProjectSelect withBlank={false} allowClear={false} onChange={onDefaultProjectChange} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="allowedProjects" label="Other Allowed Project(s)">
                        <Select style={{width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10}} onChange={onAllowedProjectsChange} allowClear showSearch optionFilterProp="children" 
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} mode="multiple">
                            {allowedProjectsOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="userGroup" label="User Group"
                        rules={[
                            { required: true, message: "User Group is required." },
                        ]}>
                        <UserGroupSelect withBlank={false} allowClear={false} onChange={onUserGroupChange} marginLeft={10}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" icon={<LeftOutlined />}>Cancel</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="User:"
                    subTitle="Create user"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default UserNew