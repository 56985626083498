import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, Descriptions, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, LOADING, MENUPATH_PROJECT, SEARCHOPTIONVIEWWIDTH } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const ProjectNew = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
        
    const createProject = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${APIURL}project/create/`, {
                name: values.projectName,
                project_id: values.projectId
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`New project ${values.projectName} created.`)
                navigate(MENUPATH_PROJECT)
            })
            .catch( error => {
                reportError(error, "Failed to create new project.")
            })
            .finally(() => {
                setIsLoading(true)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const onSave = async (e) => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create project confirmed?</p></Space>,
            onOk() { createProject() },
            onCancel() {},
            centered: true
        })
    }

    const onBack = () => {
        navigate(MENUPATH_PROJECT)
    }

    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Project">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create project</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="projectName" label="New Project Name"
                        rules={[
                            { required: true, message: "Project name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Project Name" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="projectId" label="New Project ID"
                        rules={[
                            { required: true, message: "Project ID is required."},
                        ]}>
                        <Input maxLength={3} minLength={3} placeholder="Project ID (3 alphanumeric)" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" icon={<LeftOutlined />}>Cancel</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Project:"
                    subTitle="Create project"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default ProjectNew