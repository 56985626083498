import { message, Modal, Image } from 'antd'
import { DATETIMEFORMAT } from "./systemparameter"
import dayjs from 'dayjs'


const { confirm } = Modal

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
}

export const reportError = (error, customMessage="") => {
    // Error message duration in seconds
    const MAXLEN = 200
    const second = 5

    // non_field_errors
    const obj = error?.response?.data?.non_field_errors
    if(obj !== undefined) {
        //alert(0)
        message.error(obj.join(" "))
    }
    else {
        // Other error
        const otherErrorMsg = error?.response?.data
        //console.log(otherErrorMsg)

        if(otherErrorMsg !== undefined) {
            if(otherErrorMsg?.detail != undefined)  {
                //alert(1)
                message.error(otherErrorMsg.detail, second)
            }
            else if(typeof(otherErrorMsg) === "object") {
                //alert(2)
                let msg = ""
                for(var key in otherErrorMsg) msg = `${msg} ${otherErrorMsg[key]}`
                message.error(msg, second)
            }
            else {
                //alert(otherErrorMsg)
                if(otherErrorMsg.length < MAXLEN)
                    message.error(otherErrorMsg, second)
                //else
                    //message.error("Error message is too long to be displayed.")
            }
        }
    }

    //message.error(error.message)
    if(customMessage !== "") message.error(customMessage)
}

export const numberWithCommas = (number) => {
    if(number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    else
        return "0"
}

export const stringNumberSorter = (a, b) => {
    return parseFloat(a.replace(',', '')) - parseFloat(b.replace(',', ''))
}

export const localDateTimeSorter = (a, b) => {
    let aMoment = dayjs(a, DATETIMEFORMAT)
    let bMoment = dayjs(b, DATETIMEFORMAT)
    return aMoment.unix() - bMoment.unix()
}

export const compressImage = (src, newX, newY) => {
    return new Promise((res, rej) => {
        const img = new Image()
        img.src = src
        img.onload = () => {
            const elem = document.createElement('canvas')
            elem.width = newX
            elem.height = newY
            const ctx = elem.getContext('2d')
            ctx.drawImage(img, 0, 0, newX, newY)
            const data = ctx.canvas.toDataURL()
            res(data)
        }
        img.onerror = error => rej(error)
    })
}

export const getBase64 = function (file) {
    var reader = new FileReader()
    return new Promise(function (resolve) {
        reader.addEventListener('load', function () { return resolve(String(reader.result)); })
        reader.readAsDataURL(file)
    })
}

export async function createImageFile(url) {
    let response = await fetch(url)
    let data = await response.blob()
    let metadata = {
      type: 'image/jpeg'
    }
    let filenameArray = url.split('/')
    let filename = filenameArray[filenameArray.length - 1]
    let file = new File([data], filename, metadata)
    return file
  }
  