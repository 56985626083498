import { Table, Row, Col, Space, Button, Form, Collapse, Pagination, message, Select, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError } from '../common/utility'
import dayjs from 'dayjs'

const { Panel } = Collapse
const { Option } = Select


const ProjectTable = () => {
    
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [form] = Form.useForm()
    const [projectId, setProjectId] = useState(0)
    const [project, setProject] = useState("")
    const [locationId, setLocationId] = useState(0)
    const [location, setLocation] = useState("")
    const [projectOption, setProjectOption] = useState([])
    const [projectDataSource, setProjectDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    const searchProject = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}projectpaginated/`, {
            params: { 
                project: projectId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search project.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( project => {
            data.push({
                id: project.id,
                name: project.name,
                projectId: project.project_id
            })
        })
        
        setProjectDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                                
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        if(projectId != 0) searchCriteria = `${searchCriteria}\nProject Name: ${project}`
                                
        axios.get(`${APIURL}project/download/`, {
            params: { 
                project: projectId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Project ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const getProject = (locationId) => {
        axios.get(`${APIURL}project/`, {
            params: {
                location: locationId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( project => <Option key={project.id}>{project.name}</Option>)
            setProjectOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get project(s).")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchProject(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchProject(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate("/projectnew")
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/projectupdate", 
                    search: `?id=${record.id}&name=${record.name}&projectId=${record.projectId}`
                }) 
            }
        }
    }

    const onProjectChange = (e, value) => {
        if(e != undefined) {
            setProjectId(e)
            setProject(value.children)
        }
        else {
            setProjectId(0)
            setProject("")
        }
    }

    useEffect(() => {
        getProject()
        searchProject(currentPage)
    }, [])

    const columns = [
        { title: 'Project Name', dataIndex: 'name', key: 'name', align: 'center', sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: 'Project ID', dataIndex: 'projectId', key: 'projectId', align: 'center', sorter: (a, b) => a.projectId.localeCompare(b.projectId) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search Project" key="1">
                        <Row justify="center">
                            <>
                            <Col span={6}/>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={6}/>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Project Name :</Col>
                            <Col span={3}>
                                <Select onChange={onProjectChange} style={{width: SEARCHOPTIONVIEWWIDTH}} allowClear showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {projectOption}
                                </Select>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>
                        
                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={projectDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default ProjectTable