import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, ALLOWED_PROJECTS, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH, MENUPATH_PROJECT } from "./systemparameter"
import { reportError } from "./utility"
import { getUserAuthToken, getUserProjectId, setUserProject, refreshUserSession, OTHERSYSPARAM, SYSPARAM } from "./usersession"
import { useNavigate } from "react-router"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const MainProjectSelect = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const navigate = useNavigate()
    const [projectOption, setProjectOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
                
    //-------------------
    // Handle project change
    //-------------------
    const onProjectChange = (projectId) => {
        const whichProject = projectOption.filter( option => option.key == projectId )
        setUserProject(projectId, whichProject[0].props.children)

        navigate(MENUPATH_PROJECT)
        
        window.location.reload()
    }

    const getAllProject = () => {
        axios.get(`${APIURL}project/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            // To add user's allowed sites ONLY.
            const data = response.data.results.filter( project => String(SYSPARAM(ALLOWED_PROJECTS)).includes(project.id) )

            const options = data.map( project => <Option key={project.id}>{project.name}</Option> )
            setProjectOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get project(s)")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    useEffect(() => {
        getAllProject()
    }, [])
    
    return(
        <Select style={{width: optionWidth}} defaultValue={getUserProjectId()} onChange={onProjectChange}>
            {projectOption}
        </Select>
    )
}

export default MainProjectSelect